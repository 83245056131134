import React from 'react'
import styled from 'styled-components'
import { primary } from '../../constants/theme'

const Back = styled.div`
  background-color: #00b3ff;
  padding: 5rem;
  margin: 0.5rem 10rem 5rem;
`
const H1 = styled.h1`
  color: white;
  font-size: 4rem;
  margin: 0;
  text-align: center;
  & span {
    font-size: 4rem;
  }
`
const Number = styled.span`
  display: block;
  font-size: 3rem;
  text-align: center;
  width: 100%;
`

export const LeftSpan = styled.span`
    display: block;
    margin-bottom: 10px;
    text-align: left;
    width: 100%;
`

export const SmallHeading = styled.span`
    color: ${primary};
    display: block;
    font-weight: 600;
    width: 100%;
`

const Typo = ({ title, number, children }) => (
  <div>
    <Number>{number}</Number>
    <Back>
      <H1>{children}</H1>
    </Back>
  </div>
)

export default Typo
